import { useContext } from 'react';
import { Panel, PanelConditions } from './DeviceConfig'
import { OrderContext } from './OrderContext';
import { DeviceConfigContext } from './DeviceConfigContext';
export interface ImagePanel extends Panel {
  url:string
}

export const ImagePanelComponent:React.FC<ImagePanel> = ({ position, size, conditions, url }: ImagePanel) => {
  const context = useContext(OrderContext);
  const deviceConfigContext = useContext(DeviceConfigContext);

  let matchesConditions = true;
  if (conditions?.includes(PanelConditions.ActiveOrder)) {
    matchesConditions &&= (context?.order?.isActive) || false;
  }

  if (conditions?.includes(PanelConditions.NoActiveOrder)) {
    matchesConditions &&= (!context?.order?.isActive) || false;
  }

  if (conditions?.includes(PanelConditions.PostOrderTransition)) {
    let timeSinceCompletion = null;
    const now = new Date();
    if (context?.order != null &&
        context?.order?.isActive === false &&
        context?.order?.completed_at) {
          timeSinceCompletion = now.getTime()-context.order.completed_at.getTime();
      }
      matchesConditions &&= (timeSinceCompletion && (timeSinceCompletion < (deviceConfigContext?.deviceConfig?.options.postOrderTransitionTime || 0))) || false;
  }

  return (
    matchesConditions ?
    <div className='image-panel' style={{
      left: position.left,
      top: position.top,
      width: size.width,
      height: size.height,
      backgroundImage: `url(${url})`
    }}>
      &nbsp;
    </div> : null
  );
};