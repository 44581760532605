import { createConsumer } from "@rails/actioncable";

interface ICableApp {
  cable: ReturnType<typeof createConsumer>;
}

const CableApp: ICableApp = {
  cable: createConsumer(process.env.REACT_APP_CABLE_URI),
};

export default CableApp;
