import React, { useState, useEffect } from 'react';
import '../App.css';
import { ConnectionState } from '../Components/ConnectionState';
import { Order } from '../order';
import CableApp from "../actionCable";
import { DeviceConfig, PanelTypes } from '../Components/DeviceConfig';
import axios from 'axios';
import { DeviceConfigContext } from '../Components/DeviceConfigContext';
import { OrderContext } from '../Components/OrderContext';
import { Panels } from '../Components/Panels';
import { getClientSecretFromLocal } from '../utilities';
import { Conversation, ConversationContext } from '../Components/ConversationContext';

const defaultLayout = {
  "options": { "postOrderTransitionTime": 5000 },
  "layout": {
    "panels": [
      {
        "type": PanelTypes.ImagePanel,
        "position": { "left": "0px", "top": "0px" },
        "size": { "width": "100%", "height": "100%" },
        "url": "/digital-assets/cm-logo-25.svg",
        "conditions": []
      }
    ]
  }
};

class VoicePayload {
  order?:Order;
  conversation:Conversation[] = [];
}

export const Home = () => {
  const [isConnected, setIsConnected] = useState(false);
  const [deviceConfig, setDeviceConfig] = useState<DeviceConfig|undefined>(defaultLayout);
  const [order, setOrder] = useState<Order>();
  const [conversation, setConversation] = useState<Conversation>();

    // TODO: hook up a CableApp subscription for ConfigChannel
  function onConfigEvent(value: DeviceConfig): void {
    console.log(`Received config event: ${JSON.stringify(value)}`);
    if (value) {
      setDeviceConfig(value);
    } else {
      console.log('Received invalid config');
    }
  }

  useEffect(() => {
    function onOrderEvent(value: Order | undefined): void {
      if (value) {
        if (value.isActive === undefined) value.isActive = true;
        setOrder(value);
      } else {
        // TODO: We can't access `order` here without causing a warning
        // ``` React Hook useEffect has a missing dependency: 'order'.
        // but if we fix that, we start tripping the return / subscription.unsubscribe, causing other issues
        // creating a fake placeholder Order for now, but that might have other issues longer-term
        // This is used for tracking the order completion time and the PostOrderTransition
        setOrder(Object.assign({}, new Order(0), {isActive:false, completed_at: new Date()}));
      }
    }
    const subscription = CableApp.cable.subscriptions.create(
      {
        channel: "ActiveOrderChannel",
        token: getClientSecretFromLocal(),
      },
      {
        connected: () => {
          setIsConnected(true);
          console.log("Connected to WebSocket");
        },
        disconnected: () => {
          setIsConnected(false);
          console.log("Disconnected from WebSocket");
        },
        received: (data: Order|VoicePayload) => {
          if ((data as VoicePayload).order || (data as VoicePayload).conversation) {
            console.log(`We received a VoicePayload with order: ${JSON.stringify((data as VoicePayload).order)}\nconversation: ${JSON.stringify((data as VoicePayload).conversation)}\n\n`);
            //console.log(`Set conversation = ${JSON.stringify((data as VoicePayload).conversation[(data as VoicePayload).conversation.length-1])}\n\n\n`)
            onOrderEvent((data as VoicePayload).order);
            setConversation((data as VoicePayload).conversation[(data as VoicePayload).conversation.length-1]);
          } else {
            onOrderEvent((data as Order));
          }
        },
      }
    );

    return () => {
      subscription.unsubscribe(); // Use `unsubscribe` here
    };
  }, []);

  useEffect(() => {
    const fetchDeviceConfig = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_API_URI}/config/`
        );
        let updatedDeviceConfig: DeviceConfig = response.data;
        setDeviceConfig(updatedDeviceConfig);
      } catch (error) {
        console.error("Error fetching deviceConfig:", error);
      }
    };
    fetchDeviceConfig();
  }, []);

  useEffect(() => {
    if (deviceConfig?.options.postOrderTransitionTime) {
      const now = new Date();
      if (
        order &&
        !order.isActive &&
        order.completed_at &&
        now.getTime() - order.completed_at.getTime() <
          deviceConfig?.options.postOrderTransitionTime
      ) {
        const postOrderTransitionTimer = setTimeout(() => {
          setOrder(Object.assign({}, order));
        }, deviceConfig?.options.postOrderTransitionTime);

        return () => clearTimeout(postOrderTransitionTimer); // Cleanup on unmount or before next effect run
      }
    }
  }, [deviceConfig, order]);

  return (
    <div className="App">
      <DeviceConfigContext.Provider value={{ deviceConfig, setDeviceConfig }}>
        <OrderContext.Provider value={{ order, setOrder }}>
          <ConversationContext.Provider value={{ conversation, setConversation }}>
            <Panels />
          </ConversationContext.Provider>
        </OrderContext.Provider>
      </DeviceConfigContext.Provider>
    </div>
  );
}
