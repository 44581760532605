import React, { useEffect } from 'react';
import { clearClientSecretFromLocal } from '../utilities';
import { useNavigate } from 'react-router-dom';

export const Logout = () => {
  const navigate = useNavigate();
  const handleLogout = () => {
    clearClientSecretFromLocal();
    navigate('/login');
  }
  useEffect(() => {
    handleLogout();
  }, []);
  return null;
}
