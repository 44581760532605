import { OrderItem } from '../order';
import { Panel, PanelConditions } from './DeviceConfig';
import { OrderContext } from './OrderContext';
import { ConversationContext } from './ConversationContext';
import { useContext, useEffect, useState } from 'react';

export interface OrderPanel extends Panel {
}

function getChildItemsString(item:OrderItem) {
  let s = '';
  if (item.modifier_groups?.[Symbol.iterator]) {
      for (const mg of item.modifier_groups) {
          if (mg.modifiers) {
            for (const mi of mg.modifiers) {
                if (s && s.length) {
                    s += ', ';
                }
                s += mi.name;
                if (mi.price > 0) {
                    s += ' (+$' + formatPrice(mi.price) + ')';
                }
            }
          }
      }
  }

  return s;
}

function formatPrice(price : number | undefined) {
  return price?.toFixed(2);
}
enum VoiceState {
  none = 0,
  speaking = 1,
  listening = 2
}
export const OrderPanelComponent:React.FC<OrderPanel> = ({ position, size, conditions }: OrderPanel) => {
  
  const context = useContext(OrderContext);
  const order = context?.order;
  const conversationContext = useContext(ConversationContext);
  const conversation = conversationContext?.conversation;
  const [showVoiceComplications,setShowVoiceComplications] = useState(conversation?.text?.length ? false : true);

  const voiceState = (!conversation || conversation.who === 'Casual') ? VoiceState.speaking : VoiceState.listening;
  /*
  setShowVoiceComplications(conversation?.text?.length ? true : false);
  setVoiceState(conversation?.who == 'Casual' ? VoiceState.speaking : VoiceState.listening);
  */
  

  const listStyles = {
   listStyle: 'none',
  };

  let matchesConditions = true;
  if (conditions?.includes(PanelConditions.ActiveOrder)) {
    matchesConditions &&= (context?.order?.isActive) || false;
  }

  let dialogColor = (voiceState === VoiceState.listening) ? '#01847E' : '#F25600';
/*
  useEffect(() => {
    if (!order || !order.items) {
      setVoiceState(VoiceState.none);
    } else {
      if (order?.items?.length % 2 === 0) { setVoiceState(VoiceState.speaking) } else { setVoiceState(VoiceState.listening) };
    }
  }, [order]);
*/
  // useEffect(() => {
  //   switch (voiceState) {
  //     case VoiceState.speaking:
  //       setDialog('Welcome! What would you like today?');
  //       break;
  //     case VoiceState.listening:
  //       setDialog('I would like some fried pickles please');
  //       break;
  //   }
  // }, [voiceState]);

  return matchesConditions ? (
    <div
      className="order-panel"
      style={{
        left: position.left,
        top: position.top,
        width: size.width,
        height: size.height,
      }}
    >
      <div className="order-total">
        <div className="float-left">Your Order # {order?.id}</div>
        <div className="float-right">Total $ {formatPrice(order?.total)}</div>
      </div>
      <div style={{ height: "100%" }}>
        {showVoiceComplications && (
          <div className="float-left" style={{ width: "50%", height: "100%" }}>
            <div style={{height:'calc(100% - 50px)',border: '2px solid #ECEBE9', boxSizing: 'border-box', borderRadius: '15px', padding: '20px', margin: '30px', textAlign: 'left', color:dialogColor}}>
            <div style={{fontSize: '25px', padding: '20px 0'}}>{(voiceState === VoiceState.speaking) ? 'Chicken Bot is speaking' : 'Chicken Bot is listening'}</div>
            <div style={{fontSize: '36px', padding: '30px 0'}}>{conversation?.text}</div>
            <div style={{width: '142px', padding: '80px 0'}}><img alt='sound waves' src={(voiceState===VoiceState.speaking?'/digital-assets/speaking.svg':'/digital-assets/listening.svg')} style={{width:'100%',height:'100%', objectFit: 'cover'}}></img></div>
            </div>
          </div>
        )}
        <div className="float-right" style={{ width: showVoiceComplications ? "50%" : "100%", height: "100%" }}>
          <ul style={listStyles}>
            {order?.items?.map((item, index) => (
              <li key={index}>
                <span className="float-left" style={{maxWidth: '85%', height: '100%', textAlign: 'left'}}>
                  <span className="item-quantity">{item.quantity}</span>
                  {item.name}
                </span>
                <span className="float-right">${formatPrice(item.price)}</span>
                <div className="item-children">{getChildItemsString(item)}</div>
              </li>
            ))}
          </ul>
          <p />
        </div>
      </div>
      {showVoiceComplications && false && (
        <div className="order-footer">
          <div>Our team are here to help. Just Say 'Crew Member'</div>
        </div>
      )}
    </div>
  ) : null;
}