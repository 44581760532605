import { useContext } from "react";
import { DeviceConfigContext } from "./DeviceConfigContext";
import { ImagePanel, ImagePanelComponent } from "./ImagePanel";
import { OrderPanel, OrderPanelComponent } from "./OrderPanel";

export function Panels() {
  const deviceConfigContext = useContext(DeviceConfigContext);

  return (
    <>
      {deviceConfigContext?.deviceConfig?.layout?.panels?.map((panel) => {
        switch (panel.type) {
          case "ImagePanel":
            const imagePanel = panel as ImagePanel;
            return <ImagePanelComponent {...imagePanel} />;
          case "OrderPanel":
            const orderPanel = panel as OrderPanel;
            return <OrderPanelComponent {...orderPanel} />;
          default:
            return <div></div>;
        }
      })}
    </>
  );
}