import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { getClientSecretFromLocal, storeClientSecretInLocal } from '../utilities';
import QRCode from 'react-qr-code';
import { Box } from '@mui/material';

const QrCode = () => {
  var sp = new URLSearchParams(window.location.search)
  const [registrationCode, setRegistrationCode] = useState<string | null>(sp.get('registration_code'));

  useEffect(() => {
    const fetchRegistrationCode = async () => {
      try {
        const clientId = process.env.REACT_APP_CLIENT_ID;
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_HOST}/api/v1/dmb/registration_code`,
          {
            headers: {
              "client-id": clientId, // Add the clientId as a custom header
            },
          }
        );
        if (response.data && response.data.registration_code) {
          setRegistrationCode(response.data.registration_code);
        }
      } catch (error) {
        setErrorMessage("Could not fetch the Registration Code");
        console.error('Error fetching Registration Code:', error);
      }
    };
  
    if (!registrationCode) { // Only fetch registration code if it's not set yet
      fetchRegistrationCode();
    }
  }, []);

  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  useEffect(() => {
    if (!registrationCode) return;

    const pollingInterval = 5000; // Poll every 5 seconds
    const timeoutDuration = 300000; // Timeout after 5 minutes
    const startTime = Date.now();

    const fetchSessionId = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_HOST}/api/v1/dmb/poll_session_status?registration_code=${registrationCode}`
        );
        if (response.ok) {
          const data = await response.json();
          if (data.session_id) {
            storeClientSecretInLocal(data.session_id);
            navigate("/");
            return true;
          }
        }
      } catch (error) {
        console.error("Error fetching session ID:", error);
      }
      return false;
    };

    const interval = setInterval(async () => {
      const shouldStop = await fetchSessionId();

      if (shouldStop || Date.now() - startTime > timeoutDuration) {
        clearInterval(interval);
        if (!shouldStop) {
          setErrorMessage("Session has timed out. Please reload to continue.");
        }
      }
    }, pollingInterval);

    return () => clearInterval(interval);
  }, [registrationCode]);

  const registrationCodeUrl = `${process.env.REACT_APP_MM_FRONTEND_HOST}/link_dmb_device?registration_code=${registrationCode}`;

  return (
    <Box
      sx={{
        maxWidth: "400px",
        margin: "auto",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: "10px",
        padding: "20px",
        paddingBottom: "100px",
      }}
    >
      <h2>Scan QR Code</h2>
      <br />
      {errorMessage ? (
        <p style={{ color: "red" }}>{errorMessage}</p>
      ) : registrationCode ? (
        <QRCode value={registrationCodeUrl || ""} />
      ) : (
        <p>Loading QR Code...</p>
      )}
      { registrationCode && !errorMessage && (
        <>
          <h2 style={{marginTop: "60px"}}>OR enter this Code Manually</h2>
          <p>{registrationCode}</p>
        </>
      )}
    </Box>
  );
};

export const Login = () => {
  const navigate = useNavigate();

  const clientSecret = getClientSecretFromLocal();
  useEffect(() => {
    if (clientSecret) {
      navigate('/');
    }
  }, []);

  return (
    <div
      style={{
        backgroundColor: "#FEF6F6",
        height: "100vh",
      }}
    >
      <Box
        sx={{
          width: "100%",
          padding: "20px 0",
          marginBottom: "50px",
          backgroundColor: "white",
          alignItems: "center",
          display: "flex",
          justifyContent: "center",
          boxShadow: "rgba(0, 0, 0, 0.05) 0px 2px 4px -2px, rgba(0, 0, 0, 0.1) 0px 4px 6px -1px"
        }}
      >
        <Box
          sx={{
            width: "57px",
            height: "50px",
            backgroundColor: "red",
            background: "url('/digital-assets/logo.svg')",
            backgroundSize: "cover",
            marginRight: "20px",
          }}
        >

        </Box>
        <h2
          style={{
            fontSize: "30px",
            fontWeight: "700",
            letterSpacing: "1px",
            margin: "0"
          }}
        >
          Checkmate
        </h2>


      </Box>
      <div className='login-box'>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
          }}
        >
          <p
            style={{
              color: "rgb(96, 101, 137)",
              fontSize: "20px",
              letterSpacing: "2px",
            }}
          >MENU BOARD LOGIN</p>
          <Box
            sx={{
              height: "2px",
              backgroundColor: "red",
              flex: "1",
              marginLeft: "20px",
              width: "100px"
            }}
          >
          </Box>
        </Box>
        <QrCode />
      </div>
    </div>

  );
}
