// TODO: Move all types to a common npm package
export class OrderCustomer {
    first_name?: string;
    last_name?: string;
    email?: string;
    phone?: string;
}

export class OrderItem {
    name: string = '';
    price: number = 0;
    quantity: number = 0;
    merchant_supplied_id?: string;
    special_instructions?: string;
    modifier_groups?: OrderModifierGroup[] = [];
}

export class OrderModifierGroup {
    name: string = '';
    modifiers: OrderItem[] = [];
    merchant_supplied_id?: string;
}

export class Order {
    id: number;
    isActive?: boolean = true;
    merchant_id?: string;
    customer_info?: OrderCustomer;
    special_instructions?: string;
    tax: number = 0;
    tip: number = 0;
    subtotal: number = 0;
    total: number = 0;
    is_pickup: boolean = false;
    delivery_fee: number = 0;
    estimated_pickup_time?: Date;
    created_at: Date;
    completed_at?: Date;
    promo_code?: string;
    items: OrderItem[] = [];
    type?: string;

    constructor(id: number, created_at?: Date) {
        this.id = id;
        this.created_at = created_at ? created_at : new Date();
    }
}