export interface DeviceConfig {
  options: {
    postOrderTransitionTime: number;
  }
  layout: {
    panels: Panel[];
  };
}

export interface Panel {
  type: PanelTypes
  conditions:PanelConditions[];
  position:PanelPositionProps,
  size:PanelSizeProps
}

export interface PanelPositionProps {
  top: string;
  left: string;
}

export interface PanelSizeProps {
  width: string;
  height: string;
}

export enum PanelTypes {
  ImagePanel = "ImagePanel",
  OrderPanel = "OrderPanel",
  MenuItemPrice = "MenuItemPrice"
}

export enum PanelConditions {
  ActiveOrder = "ActiveOrder",
  NoActiveOrder = "NoActiveOrder",
  PostOrderTransition = "PostOrderTransition",
}